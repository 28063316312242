import React from 'react'
import { Link } from 'gatsby'

import Card from './card'
import styled from 'react-emotion'
import { COLOR_PALLETE } from '../utils/colors';

const CategoryCardLink = styled(Link)`
  box-shadow: none;
  flex: 0 0 45%;
  margin-right: 10px;
`

const CategoryCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  cursor: 'pointer';
  flex-direction: column;
`

const CategoryHeader = styled('div')`
  padding: 5px 5px 0px;
  display: flex;
  justify-content: space-between;
`
const CategoryName = styled('h4')`
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
`

const CategoryCount = styled('span')`
  color: ${COLOR_PALLETE.LIGHTEST_BLACK};
`

const CategoryDescription = styled('p')`
  color: ${COLOR_PALLETE.LIGHTER_BLACK};
  margin-bottom: 0;
  margin-left: 3px;
  padding: 0px 10px 5px;
`
const CATEGORY_DESCRIPTIONS = {
  Learn: 'Things I discovered while learning something new.',
  Tutorial: 'Step by step guide on how to do things',
  Tips: 'Tips to the rescue',
  General : 'Posts that can vary from introduction to anything random.',
  Tech: 'Posts that talk about a technology, it\'s pros and cons.',
  'Talk-o-Tuesdays':
    'Collection of my favorite talks, with a brief summary which you can refer to later',
}
class Category extends React.Component {
  render() {
    let { fieldValue, totalCount } = this.props.category

    return (
      <CategoryCardLink to={`/categories/${fieldValue}`}>
        <CategoryCard>
          <CategoryHeader>
            <CategoryName>{fieldValue} </CategoryName>
            <CategoryCount>{totalCount}</CategoryCount>
          </CategoryHeader>
          <CategoryDescription>
            {CATEGORY_DESCRIPTIONS[fieldValue]}
          </CategoryDescription>
        </CategoryCard>
      </CategoryCardLink>
    )
  }
}

export default Category

import React from 'react'
import styled from 'react-emotion'
import Category from './category'

const CategoriesContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 768px) {
    flex-flow: column wrap;
  }
`

class CategoryList extends React.Component {
  render() {
    let { categories } = this.props
    return (
      <CategoriesContainer>
        {categories.map(category => (
          <Category key={category.fieldValue} category={category} />
        ))}
      </CategoriesContainer>
    )
  }
}

export default CategoryList
